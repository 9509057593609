import { useEffect } from 'react';

const useRemoveAllEventListeners = () => {
  useEffect(() => {
    const removeAllListeners = () => {
      const container = document.querySelector('#scroll-container');
      if (container) {
        const elements = container.querySelectorAll('*');
        elements.forEach((element) => {
          const newElement = element.cloneNode(true);  // Create a fresh clone
          element.replaceWith(newElement);  // Replace original element with its clone
        });
      }
    };

    // Call the function once to ensure all current listeners are removed within #scroll-container
    removeAllListeners();

    // Return cleanup function to remove listeners when component unmounts
    return () => {
      removeAllListeners();
    };
  }, []);
};

export default useRemoveAllEventListeners;
