import React, { useEffect, useState } from 'react';
import './Connectr.css';
import anime from 'animejs';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import pageflip from "./../img/pageflip.png";


const validProjects = [ 'Portfolio', 'Cursor Pursuit', 'Connectr.', 'Numero', 'Franse Werkwoorden', '101Mailz' ];

const ProjectPage = () => {
    const navigate = useNavigate();

    const location = useLocation(); 
    const pathParts = location.pathname.split('/');    
    var projectName = pathParts.length > 2 ? pathParts[2] : '';
    if (projectName === "CPS") {
        projectName = "Cursor Pursuit";
    } else if (projectName === "FWW") {
        projectName = "Franse Werkwoorden"
    }
    const initialIndex = validProjects.indexOf(projectName);
    const [currentIndex, setCurrentIndex] = useState(initialIndex !== -1 ? initialIndex : 0);

    useEffect(() => {
        console.log(validProjects[currentIndex]);
        if ($(".project-page").hasClass("peel")) {
            $(".project-page.peel .title").html(splitTextJSX(validProjects[currentIndex]));
            setTimeout(() => {
                var newUrl;
                console.log(validProjects[currentIndex] + ' item');
                if (validProjects[currentIndex] === "Cursor Pursuit") {
                    newUrl = `/projects/CPS`;
                } else if (validProjects[currentIndex] === "Franse Werkwoorden") {
                    newUrl = `/projects/FWW`;
                } 
                else {
                    newUrl = `/projects/${validProjects[currentIndex]}`;
                }
                
                // Update the URL without refreshing the page
                window.history.replaceState(null, '', newUrl);
                reloadHoverEventlisteners();
            }, 0);
        }
    }, [currentIndex]);

    const reloadHoverEventlisteners = () =>  {
        document.querySelectorAll('.letter').forEach((letter) => {
            letter.addEventListener('mouseenter', (e) => {
                const letters = document.querySelectorAll('.letter');

                letters.forEach(letter => {
                    anime({
                        targets: letter,
                        opacity: [1, 0.85],
                        translateX: anime.random(-5, 5),  // Random movement on the X-axis
                        translateY: anime.random(-5, 5),  // Random movement on the Y-axis
                        scale: 0.67,  // Enlarge the letter on hover
                        rotate: anime.random(-15, 15),  // Slight rotation
                        easing: 'spring(1, 80, 10, 0)',
                        duration: 800,
                    });
                });
                anime({
                    targets: e.target,
                    opacity: 1,
                    translateX: anime.random(-30, 30),  // Random movement on the X-axis
                    translateY: anime.random(-30, 30),  // Random movement on the Y-axis
                    scale: 1.5,  // Enlarge the letter on hover
                    rotate: anime.random(-15, 15),  // Slight rotation
                    easing: 'spring(1, 80, 10, 0)',
                    duration: 800,
                });
            });

            letter.addEventListener('mouseleave', () => {
                anime({
                    targets: '.letter',
                    opacity: 1,
                    translateX: 0,
                    translateY: 0,
                    scale: 1,  // Return to normal size
                    rotate: 0,  // Return to original position
                    easing: 'spring(1, 80, 10, 0)',
                    duration: 1000,
                });
            });
            $(".title").css("letter-spacing", "0");
        });

    };


    useEffect(() => {

        
    var value = 0;
    $(".pageflip").on("click", () => {
        console.log("reached");


        $(".project-page").css("transition", "transform 0s");
        if ($(".project-page").hasClass("peel")) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % validProjects.length);

            $(".project-page.peel").css("z-index", "0");
        }
        setTimeout(() => {
            $(".project-page").removeClass("peel");
        }, 0);
        if (!value) {
            value = 1;
        } else {
            value = 0;
        }
        $(".project-page").eq(value).css("transition", "opacity 0.55s ease-in-out, transform 1.5s ease-in-out");
        setTimeout(() => {
            $(".project-page").eq(value).addClass("peel");
            $(".project-page").eq(value).css("z-index", "3");
            setTimeout(() => {
                $(".project-page").eq(value).css("z-index", "0");
            }, 1780);
        }, 0);
    })


        $(".project-page").css("opacity", "1");
        // Apply hover animation to each letter

        reloadHoverEventlisteners();

        let isScaledUp = false;

        $(".exit").on("click", () => {
            $(".black-cover").css("height", "100vh");
            setTimeout(() => {
                const scrollPosition = sessionStorage.getItem("scrollPosition");
                sessionStorage.removeItem("scrollPosition")
                navigate("/");
                setTimeout(() => {
                    $(window).scrollTop(scrollPosition);
                }, 250);
            }, 730);
        });

        $(".exit").on("mouseenter", (e) => {
            if ($(".circle").length > 0) return;
            const target = e.target;
            const rect = target.getBoundingClientRect();
            const circle = $(`<div class="circle" style="left: ${e.clientX - rect.left}px; top: ${e.clientY - rect.top}px"></div>`);
            circle.appendTo('.exit');

            setTimeout(() => {
                circle.css("transform", "scale(100)");
                isScaledUp = true;
            }, 50);

            $(".exit div:nth-child(1), .exit div:nth-child(2)").css({
                "background-color": "black",
                "transform": "scale(1.5) rotate(15deg)"
            });

            $(".bigCircle circle").css("opacity", "0.4");
            $(".bigCircle").css({
                "transform": "scale(3)",
                "border": "2px solid rgb(31 149 248 / 0%)"
            });
        }).on("mouseleave", (e) => {
            if (!isScaledUp) return; // Wait until the circle has scaled up

            const parentRect = $(e.target).closest('.exit')[0].getBoundingClientRect();
            const exitX = e.clientX - parentRect.left;
            const exitY = e.clientY - parentRect.top;

            $(".circle").css({
                "left": `${exitX}px`,
                "top": `${exitY}px`,
                "transform": "scale(0)"
            });

            $(".exit div:nth-child(1), .exit div:nth-child(2)").css({
                "background-color": "#fbf8f3",
                "transform": "scale(1) rotate(0deg)"
            });

            setTimeout(() => {
                $(".circle").remove();
                isScaledUp = false; // Reset the flag
            }, 750); // Adjust this timeout to match the transition duration

            $(".bigCircle circle").css("opacity", "1");
            $(".bigCircle").css({
                "transform": "scale(1)",
                "border": "2px solid #1F95F8"
            });
        });
    }, [navigate]);

    // Function to split the word into spans
    const splitText = (text) => {
        return text.split('').map((letter, index) => (
            <span key={index} className="letter hoverable">{letter}</span>
        ));
    };

    const splitTextJSX = (text) => {
        return text.split('').map((letter, index) => (
            `<span key=${index} class='letter hoverable'>${letter}</span>`
        )).join('');
    };
    

    return (
        <div>
        <div className="project-page peel">
            <div className="title">
                {splitText(`${projectName}`)}
            </div>
            <div className="separator"></div>
            <div className="lines">
                {Array.from({ length: 25}).map((_, i) => (
                    <div key={i}></div>
                ))}
            </div>

            <p className="temp-text">
                Haven't done the writing for the projects yet sorry! Will do this after my upcoming exams, so expect all case studies online at the start of November 24.
            </p>

            <div className="exit">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="hoverable pgf">
                <img src={pageflip} className="pageflip"/>
            </div>

        </div>

        <div className="project-page page-v2">
            <div className="title">
                {splitText(`${projectName}`)}
            </div>
            <div className="separator"></div>
            <div className="lines">
                {Array.from({ length: 25}).map((_, i) => (
                    <div key={i}></div>
                ))}
            </div>

            <p className="temp-text">
                Haven't done the writing for the projects yet sorry! Will do this after my upcoming exams, so expect all case studies online at the start of November 24.
            </p>

            <div className="exit">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="hoverable pgf">
                <img src={pageflip} className="pageflip"/>
            </div>
        </div>
        </div>
    );
};

export default ProjectPage;
