import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import './index.css';
import './Fonts.css';
import reportWebVitals from './reportWebVitals';
import butter from './butter.js';
import AppProjects from './AppProjects.js';
import AppPCLow from './AppPCLow.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

butter.cleanup();
butter.init({ wrapperDamper: .014 });

function updateStyles(pathname) {
    if (pathname.startsWith('/projects')) {
        $('html, body, #root').css({
            height: '100vh',
            width: '100vw',
            overflow: 'hidden'
        });
    } else {
        $('html, body, #root').css({
            height: '7250px',
            width: '100vw',
            overflowX: 'hidden'
        });
    }
}

const validProjects = [
    'Portfolio',
    'CPS',
    'Connectr.',
    'Numero',
    'FWW',
    '101Mailz'
];

function useProjectPathValidation() {
    const location = useLocation();
    const navigate = useNavigate();
    
    React.useEffect(() => {
        const pathname = location.pathname;

        if (pathname.startsWith('/projects/')) {
            const projectName = pathname.split('/projects/')[1];

            // Redirect if project name is invalid
            const correctName = validProjects.find(name => name.toLowerCase() === projectName.toLowerCase());
            if (!correctName) {
                navigate('/', { replace: true });
            } else if (correctName !== projectName) {
                // Correct the case of the project name
                navigate(`/projects/${correctName}`, { replace: true });
            }
        }
        
        updateStyles(pathname);
    }, [location.pathname, navigate]);
}

function App() {
    useProjectPathValidation();

    return (
        <Routes>
            <Route path="/projects/*" element={<AppProjects />} />
            <Route path="/*" element={<AppPCLow />} />
        </Routes>
    );
}

root.render(
    <Router>
        <App />
    </Router>
);

reportWebVitals();
